const ADVERTISERS = [
  {
    id: "a5",
    name: "Advanced Seamless",
    phone: "512-881-9204",
    logo: "/assets/logos/advancedseamless.png",
    category: "Business",
    services: "Seamless Gutters, Leaf Protection, Rain Barrels and Chains",
  },
  {
    id: "a6",
    name: "Air Quality Treaters",
    phone: "210-868-6278",
    logo: "/assets/logos/aqt.png",
    category: "Business",
    services: "Whole-home Sanitation and Disinfectant",
  },
  {
    id: "a7",
    name: "1908 House of Wine & Ale",
    phone: "210-468-3887",
    logo: "/assets/logos/1908.png",
    category: "Restaurant",
    services: "80 Types of Wine, 40+ Craft Beers, Amazing Appetizers",
    town: "Cibolo",
  },
  {
    id: "a8",
    name: "Bergheim Plumbing",
    phone: "210-264-1432",
    logo: "/assets/logos/bergheim.PNG",
    category: "Business",
    services: "Plumbing Repairs, New Residential & Commercial, Water Heaters",
  },
  {
    id: "a9",
    name: "The Birdhouse",
    phone: "830-608-0690",
    logo: "/assets/logos/birdhouse.jpg",
    category: "Restaurant",
    services: "Fancy Chicken, Fine Cocktails, Amazing Brunch",
    town: "Gruene",
  },
  {
    id: "a10",
    name: "Blue Bonnet Palace",
    phone: "210-651-6702",
    logo: "/assets/logos/bluebonnet.png",
    category: "Restaurant",
    services: "Dancehall, Live Music & DJ, Smokehouse & Cantina, Catering",
    town: "Garden Ridge/Selma",
  },
  {
    id: "a11",
    name: "Bootleggers Pizza Parlor",
    phone: "830-327-1199",
    logo: "/assets/logos/bootleggers.PNG",
    category: "Restaurant",
    services: "Gourmet Pizza, House-made Moonshine, Pizza by the Slice",
    town: "New Braunfels",
  },
  {
    id: "a12",
    name: "Breakwater Grill & Cantina",
    phone: "830-935-4103",
    logo: "/assets/logos/breakwater.png",
    category: "Restaurant",
    services: "Best Sunset Views on Canyon Lake, Live Music on Weekends",
    town: "Canyon Lake",
  },
  {
    id: "a13",
    name: "Bulverde Car Wash",
    phone: "830-572-5944",
    logo: "/assets/logos/bulverdecarwash.JPG",
    category: "Business",
    services: "Unlimited Carwash Club Packages, 24/7 self-service wash",
  },
  {
    id: "a60",
    name: "Clear Modern Mortgage",
    phone: "508-243-1019",
    logo: "/assets/logos/clearmodern.png",
    category: "Business",
    services: "Home & Refinancing Mortgages, Low Rates, Expert Guidance",
  },
  {
    id: "a2",
    name: "Cody's",
    phone: "830-609-9194",
    logo: "/assets/logos/codys.PNG",
    category: "Restaurant",
    services: "Fresh, Elegant Dining, Craft Cocktail Bar",
    town: "New Braunfels",
  },
  {
    id: "a14",
    name: "Cowboy's Armory",
    phone: "210-608-5919",
    logo: "/assets/logos/cowboys.png",
    category: "Business",
    services: "Custom Builds, Specialty Ammo, #1 Canik Dealer in Texas",
    town: "Canyon Lake",
  },
  {
    id: "a15",
    name: "DB Integrity",
    phone: "210-645-5597",
    logo: "/assets/logos/dbintegrity.png",
    category: "Business",
    services: "Water, Fire, Smoke and Mold Mitigation",
  },
  {
    id: "a16",
    name: "Distinctive Concrete",
    phone: "830-310-0494",
    logo: "/assets/logos/distinctiveconcrete.png",
    category: "Business",
    services:
      "Garage Epoxy Floors, Sport Courts, Stained Driveways, Pool Deck Refinishing",
  },
  {
    id: "a17",
    name: "Divots Indoor Golf",
    phone: "830-660-4377",
    logo: "/assets/logos/divots.png",
    category: "Fun",
    services: "3 Large Bays, 368 Courses, Great For Parties",
    town: "Canyon Lake",
  },
  {
    id: "a18",
    name: "Double Schotts Sports Bar & Grill",
    phone: "830-632-5355",
    logo: "/assets/logos/doubleschotts.png",
    category: "Restaurant",
    services: "Sports on 30+ TVs, Free Pool & Shuffleboard, Daily Happy Hour",
    town: "New Braunfels",
  },
  {
    id: "a19",
    name: "Dove's Event Bartending",
    phone: "720-537-8611",
    logo: "/assets/logos/doves.png",
    category: "Business",
    services: "Private Event Bartending, Mixology Classes",
  },
  {
    id: "a20",
    name: "Empower Aesthetics",
    phone: "830-359-9661",
    logo: "/assets/logos/empower.png",
    category: "Business",
    services: "Botox, Filler, Lashes, Chemical Peels",
    town: "Canyon Lake",
  },
  {
    id: "a21",
    name: "Five Rings Financial",
    phone: "830-832-2682",
    logo: "/assets/logos/fiverings.png",
    category: "Business",
    services: "Tax Free Retirement, Living Benefits, Mortgage Protection",
  },
  {
    id: "a22",
    name: "Fork and Spoon",
    phone: "830-626-6999",
    logo: "/assets/logos/forkandspoon.png",
    category: "Restaurant",
    services: "Best French Toast in Texas",
    town: "New Braunfels",
  },
  {
    id: "a23",
    name: "Freddy C's",
    phone: "830-358-7050",
    logo: "/assets/logos/freddycs.jpg",
    category: "Restaurant",
    services: "Bar & Patio, Weekend Brunch, Daily Happy Hour",
    town: "New Braunfels",
  },
  {
    id: "a24",
    name: "Frenchies Modern Nail Care",
    phone: "830-632-3400",
    logo: "/assets/logos/frenchies.jpeg",
    category: "Business",
    services: "Manicures, Pedicures, Parties, Friendly Professionals",
    town: "New Braunfels",
  },
  {
    id: "a25",
    name: "Fun Buns",
    phone: "830-885-4305",
    logo: "/assets/logos/funbuns.png",
    category: "Restaurant",
    services: "Delicious Burgers, Full Bar, Amazing Desserts & Cocktails",
    town: "Bulverde/Spring Branch",
  },
  {
    id: "a26",
    name: "Galpao Gaucho",
    phone: "210-497-2500",
    logo: "/assets/logos/galpogaucho.png",
    category: "Restaurant",
    services: "Brazilian Steakhouse, 17 Cuts of Meat, Extensive Salad Bar",
    town: "San Antonio",
  },
  {
    id: "a27",
    name: "Graybrook Construction",
    phone: "830-214-6349",
    logo: "/assets/logos/graybrook.png",
    category: "Business",
    services:
      "Bathroom & Kitchen Remodels, Handyman Services, Decks & Pergolas",
  },
  {
    id: "a28",
    name: "Guadalajara Bar & Grill",
    phone: "830-312-7843",
    logo: "/assets/logos/guadalajarabargrill.png",
    category: "Restaurant",
    services: "Breakfast Every Day, Drive-Thru, Full Bar, Catering",
    town: "New Braunfels",
  },
  {
    id: "a29",
    name: "Guadalajara Meat Market",
    phone: "830-643-1500 / 830-312-7061",
    logo: "/assets/logos/guadalajarameat.png",
    category: "Restaurant",
    services: "Authentic Hispanic Cuts of Meat, 2 Locations",
    town: "New Braunfels",
  },
  {
    id: "a30",
    name: "Hacienda El Tapatio",
    phone: "830-632-5154",
    logo: "/assets/logos/haciendaeltapitio.png",
    category: "Restaurant",
    services: "Breakfast Every Day, Drive-Thru, Full Bar, Catering",
    town: "New Braunfels",
  },
  {
    id: "a31",
    name: "Home Builder Assist",
    phone: "830-507-5956",
    logo: "/assets/logos/hba.jpg",
    category: "Business",
    services: "We Bridge the Knowledge Gap on Custom Home Builds",
  },
  {
    id: "a32",
    name: "The Hideaway",
    phone: "830-620-5376",
    logo: "/assets/logos/hideaway.png",
    category: "Restaurant",
    services:
      "Fresh Hill Country Kitchen, Indoor/Outdoor Venue, Family Friendly",
    town: "New Braunfels",
  },
  {
    id: "a33",
    name: "J&J Tree Care",
    phone: "210-914-6619",
    logo: "/assets/logos/jjtree.png",
    category: "Business",
    services:
      "Tree Removal, Trimming and Installation, Stump Grinding, Chipping Service",
  },
  {
    id: "a34",
    name: "Kindling Texas Kitchen",
    phone: "210-957-0158",
    logo: "/assets/logos/kindling.jpg",
    category: "Restaurant",
    services: "Farm Fresh Regional Texas Cuisine, Custom-craft Cocktails",
    town: "Cibolo",
  },
  {
    id: "a35",
    name: "Krause's",
    phone: "830-625-2807",
    logo: "/assets/logos/krause.png",
    category: "Restaurant",
    services:
      "100+ Beer Taps and Full Bar, Live Music, Breakfast, Lunch, Dinner",
    town: "New Braunfels",
  },
  {
    id: "a36",
    name: "Las Fontanas",
    phone: "830-626-3310 / 830-627-4551",
    logo: "/assets/logos/lasfontanas.jpg",
    category: "Restaurant",
    services: "Mexican Kitchen, Two Great Locations",
    town: "New Braunfels",
  },
  {
    id: "a37",
    name: "Leapfrog Promotions",
    phone: "210-236-7270",
    logo: "/assets/logos/leapfrog.png",
    category: "Business",
    services: "Design, Printing, Promotional Products, Websites & E-Commerce",
  },
  {
    id: "a1",
    name: "Legacy Broker Group",
    phone: "210-887-0184",
    logo: "/assets/logos/legacybroker.png",
    category: "Business",
    services: "Real Estate, Residential, Land, Investment",
  },
  {
    id: "a38",
    name: "Luxury Pools & Backyards",
    phone: "210-440-9398",
    logo: "/assets/logos/luxurypools.jpeg",
    category: "Business",
    services:
      "Built in 60 Days or Less, Grottos & Waterfalls, Fire Features, Hot Tubs & Spas",
  },
  {
    id: "a39",
    name: "Melting Pot",
    phone: "210-479-6358",
    logo: "/assets/logos/meltingpot.png",
    category: "Restaurant",
    services: "Original Fondue, Fine Dining, 4 Course Meals",
    town: "San Antonio",
  },
  {
    id: "a40",
    name: "The Mess Around",
    phone: "830-620-7200",
    logo: "/assets/logos/messaround.png",
    category: "Restaurant",
    services: "Upscale Southern Cuisine in the Heart of Downtown",
    town: "New Braunfels",
  },
  {
    id: "a3",
    name: "Mr. Media",
    phone: "210-708-3630",
    logo: "/assets/logos/mrmedia.png",
    category: "Business",
    services:
      "Video Marketing & Production, Social Media, Professional Photographer",
  },
  {
    id: "a41",
    name: "Natural Bridge Caverns",
    phone: "210-651-6101",
    logo: "/assets/logos/naturalbridge.png",
    category: "Fun",
    services:
      "Cavern Tours, Twisted Trails Zip Rails & Rope Course, Gem & Fossil Mining",
    town: "Garden Ridge",
  },
  {
    id: "a42",
    name: "Oyster Bar",
    phone: "830-627-7766",
    logo: "/assets/logos/oysterbar.JPG",
    category: "Restaurant",
    services:
      "Way More Than Just Oysters, Fresh Salads, Homemade Soups, Home of the Breakfast Shot",
    town: "New Braunfels",
  },
  {
    id: "a43",
    name: "RHR Roofing",
    phone: "210-420-6640",
    logo: "/assets/logos/rhr.png",
    category: "Business",
    services: "Roof Installation, Shingle, Metal, Tile, Insurance Claims",
  },
  {
    id: "a44",
    name: "River Rose Tavern",
    phone: "210-693-2116",
    logo: "/assets/logos/riverrose.png",
    category: "Restaurant",
    services: "Live Music, Full Bar, Craft Cocktails, Bingo, Trivia",
    town: "Canyon Lake",
  },
  {
    id: "a45",
    name: "Screaming Goat Yard & Tap",
    phone: "830-402-4656",
    logo: "/assets/logos/screaminggoat.png",
    category: "Restaurant",
    services:
      "Southern-Style Food, 30 Beers on Tap, Private Event Space up to 100",
    town: "Spring Branch",
  },
  {
    id: "a4",
    name: "Shade Tree Saloon & Grill",
    phone: "830-885-5550",
    logo: "/assets/logos/shadetree.jpg",
    category: "Restaurant",
    services: "Full Bar, Full Menu, Live Music, Karoake",
    town: "Bulverde/Spring Branch",
  },
  {
    id: "a46",
    name: "Sip N Putt",
    phone: "830-358-7118",
    logo: "/assets/logos/sipnputt.png",
    category: "Fun",
    services: "18 Hole Mini Putting Course, Great Views & Sunsets, Cold Beer",
    town: "New Braunfels",
  },
  {
    id: "a47",
    name: "Sonora Bank",
    phone: "830-438-0416",
    logo: "/assets/logos/sonora.png",
    category: "Business",
    services: "The Texas Bank Since 1900",
    town: "Spring Branch",
  },
  {
    id: "a48",
    name: "Stone Terrace Gastro Pub",
    phone: "210-233-1981",
    logo: "/assets/logos/stoneterrace.png",
    category: "Restaurant",
    services:
      "Casual Elevated Dining, Craft Cocktails, Weekend Brunch, Outdoor Patio",
    town: "San Antonio",
  },
  {
    id: "a49",
    name: "Stuhle Home Interiors",
    phone: "830-660-1379",
    logo: "/assets/logos/stuhle.png",
    category: "Business",
    services:
      "We Design, Build and Furnish Your Indoor & Outdoor Living Space Dreams",
    town: "New Braunfels",
  },
  {
    id: "a50",
    name: "Suburban Buildings",
    phone: "830-308-4100",
    logo: "/assets/logos/suburban.png",
    category: "Business",
    services:
      "Barndominiums, Steel and Post-Frame Buildings, Financing Options, Generated 3D Models",
  },
  {
    id: "a51",
    name: "Taqueria El Tapatio",
    phone: "830-608-8036",
    logo: "/assets/logos/taqueriaeltapatio.png",
    category: "Restaurant",
    services: "Drive-Thru, Catering, Beer & Margaritas, Breakfast Every Day",
    town: "New Braunfels",
  },
  {
    id: "a52",
    name: "Taqueria Guadalajara",
    phone: "830-608-1664",
    logo: "/assets/logos/taqueriaguadalajara.png",
    category: "Restaurant",
    services: "Mexican Restaurant, Breakfast Every Day",
    town: "New Braunfels",
  },
  {
    id: "a53",
    name: "The Reserve On 1863",
    phone: "830-327-1044",
    logo: "/assets/logos/thereserve.png",
    category: "Restaurant",
    services:
      "Full Bar & Craft Cocktails, Allocated Bourbons, Live Music Friday & Saturday",
    town: "New Braunfels",
  },
  {
    id: "a54",
    name: "Texas Axe & Ale",
    phone: "830-312-9423",
    logo: "/assets/logos/txaxale.png",
    category: "Fun",
    services: "Family Friendly Fun, 10 Axe-Throwing Lanes, Private Parties",
    town: "Marion",
  },
  {
    id: "a55",
    name: "TX Electric",
    phone: "830-743-9985",
    logo: "/assets/logos/txelectric.png",
    category: "Business",
    services:
      "Full Electrical Service & Repairs, Home Generators, House Surge Protection",
  },
  {
    id: "a56",
    name: "Valkyrie's Kiss",
    phone: "830-481-2101",
    logo: "/assets/logos/valkyrieskiss.png",
    category: "Restaurant",
    services: "Premium Mead, Local Texas Honey, Tasting Room",
    town: "New Braunfels",
  },
  {
    id: "a57",
    name: "Water 2 Wine",
    phone: "830-609-9463",
    logo: "/assets/logos/water2wine.png",
    category: "Restaurant",
    services:
      "Award Winning Wines, Tastings, Appetizers, Bottle & Label Your Own",
    town: "New Braunfels",
  },
  {
    id: "a58",
    name: "Wildlife Ranch",
    phone: "830-438-7400",
    logo: "/assets/logos/wildliferanch.png",
    category: "Fun",
    services: "Self-Guided and Guided Tours of African Safari, Texas Style",
    town: "Garden Ridge",
  },
  {
    id: "a59",
    name: "Wraptitude",
    phone: "210-657-9727",
    logo: "/assets/logos/wraptitude.png",
    category: "Restaurant",
    services:
      "Gourmet Wraps, Burgers, Beers, Family & Pet Friendly, Catering Available",
    town: "Garden Ridge/Selma",
  },
];

export default ADVERTISERS;
